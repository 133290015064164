import author from './Author';
import permission from './Permission';
import campaign from './Campaign';
import baseAuthor from './BaseAuthor';
import uploadData from './UploadData';
import customerCare from './CustomerCare';
import customerCareView from './CustomerCareV2';
import bookingCampaign from './BookingCampaign';
import staffView from './StaffView';

export default {
	author,
	permission,
	campaign,
	baseAuthor,
	uploadData,
	customerCare,
	customerCareView,
	bookingCampaign,
	staffView
};
