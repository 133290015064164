import { RequestManager } from 'utils';

const getListCampaign = (params?: any) => {
	return RequestManager.campaign.get('/api/config/campaigns', { params });
};

const getDetailCampaign = (params?: any) => {
	return RequestManager.campaign.get('/api/config/campaign-config', { params });
};

const getProductActives = (params?: any) => {
	return RequestManager.campaign.get('/api/config/products', { params });
};

const getServiceActives = (params?: any) => {
	return RequestManager.campaign.get('/api/config/services', { params });
};

const getCategories = (params?: any) => {
	return RequestManager.campaign.get('/api/config/categories', { params });
};

const getSalons = (params?: any) => {
	return RequestManager.campaign.get(
		"/api/config/salons",
		params,
	);
};

const createCampaign = (params?: any) => {
	return RequestManager.campaign.post('/api/campaign/v2', params);
};

const updateCampaign = (campaignId: number, params?: any) => {
	return RequestManager.campaign.put(`/api/campaign/v2?campaignId=${campaignId}`, params);
};

const loadConditionAiCampaign = (params?: any) => {
	return RequestManager.campaign.post(`/api/v2/ai/condition`, params);
};

const getHistoryCampaign = (id: any, params?: any) => {
	return RequestManager.campaign.get(`/api/config/campaign-import-log/${id}`, { params });
};

export default {
	getListCampaign,
	getDetailCampaign,
	getProductActives,
	getServiceActives,
	getCategories,
	createCampaign,
	updateCampaign,
	getSalons,
	loadConditionAiCampaign,
	getHistoryCampaign
};
